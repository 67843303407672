import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/datasource/rest.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.less']
})
export class AppsTransactionsComponent implements OnInit {

  public method = "user/list";
  public list = [];
  public pagination = {
    currentPage: 1,
    itemsPerPage: 10,
    maxSizeOfPages: 35
  };

  constructor(private RService: RestService) { }

  ngOnInit(): void {
    this.loadTransactionsList();
  }

  private loadTransactionsList(): void {
    this.RService.get(this.method).subscribe((response: any) => {
      if (response.status === true) {
          this.list = response.result;
        }
    });
  }

}
