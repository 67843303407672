import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/datasource/rest.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.less']
})
export class EmailTemplatesComponent implements OnInit {

  public method = "emails/templates/format/json/";
  public list = [];

  constructor(private RService: RestService) { }

  ngOnInit(): void {
    this.RService.get(this.method).subscribe((response: any) => {
    if (response.status === true) {
        this.list = response.result;
      }
    });
  }

}
