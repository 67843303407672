import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/datasource/rest.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.less']
})
export class BaseAddComponent implements OnInit {

  constructor(private RService: RestService) { }

  ngOnInit(): void {
  }

  get list(): JSON {
    return this.list;
  }

  set list(pValue: JSON) {
    this.list = pValue;
  }

  public request(pMethod: string, pList) {
    this.RService.get(pMethod).subscribe((response: any) => {
      if (response.status === true) {
        pList = response.result;
      }
    });
  }

}
