import { environment } from 'src/environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class Config {
  private env: string; // dev, prod
  private serviceBaseURL = {
    dev: 'https://127.0.0.1:8000/',
    prod: 'https://ccms.gnfc.ge/'
  };

  private publicBaseURL = {
    dev: 'https://127.0.0.1:8000/',
    prod: 'https://ccms.gnfc.ge/'
  };

  public get() {
    if (environment.production === true) {
      this.env = 'prod';
    } else {
      this.env = 'dev';
    }
    let result;
    var vHrefPrefix;
    if (this.env === 'dev') {
      result = {
        baseURL: this.serviceBaseURL.dev,
        publicBaseURL: this.publicBaseURL.dev,
        hrefPrefix: vHrefPrefix
      };
    } else if (this.env === 'prod') {
      result = {
        baseURL: this.serviceBaseURL.prod,
        publicBaseURL: this.publicBaseURL.prod,
        hrefPrefix: vHrefPrefix
      };
    }
    return result;
  };

  public getToken(){

  }
}
