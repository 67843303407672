import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/datasource/rest.service';
import Swal from 'sweetalert2'
import * as $ from 'jquery'

@Component({
  selector: 'app-list-contests',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class ContestsListComponent implements OnInit {

  private listMethod = "contest/list";
  public list: any;
  public category_list: any;
  public pagination = {
    currentPage: 1,
    itemsPerPage: 10,
    maxSizeOfPages: 35
  };

  constructor(private RService: RestService) { }

  ngOnInit(): void {
    this.loadList();
  }

  private loadList(){
    this.RService.get(this.listMethod).subscribe(
      (response) => {
        if(response.status === true){
          this.list = response.result.rows;
          this.category_list = response.result.category_list;
        }
      }
    );
  }

  public activeAppsNavItem(){
    $("#main-menu-navigation li").removeClass("active");
    $("#nav_item_apps").addClass("active");
  }

  public addNewContest(){
    var options = "";
    $.each(this.category_list, (index, item) => {
      options +=`<option value="${item.id}">${item.title}</option>`;
    })
    var vHTMLTemplate = `
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label for="contest_category">კონკურსის კატეგორია</label>
        <select class="form-control" id="contest_category">${options}</select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="contest_open_date">დაწყების დრო</label>
        <div class="position-relative has-icon-left">
          <input type="date" id="contest_open_date" class="form-control" data-toggle="tooltip" data-trigger="hover" data-placement="top" data-title="კონკურსის დაწყების დრო">
          <div class="form-control-position">
            <i class="la la-briefcase"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="contest_end_date">დასრულების დრო</label>
        <div class="position-relative has-icon-left">
          <input type="date" id="contest_end_date" class="form-control" data-format="dd-mm-yyyy" data-toggle="tooltip" data-trigger="hover" data-placement="top" data-title="კონკურსის დასრულების დრო">
          <div class="form-control-position">
            <i class="la la-briefcase"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
    `;
    Swal.fire({
      title: 'ახალი კონკურსის დამატება',
      html: vHTMLTemplate,
      focusConfirm: false,
      confirmButtonText: "დამატება",
      showLoaderOnConfirm: true
    }).then(
      async (result) => {
        const cMethod = "contest/add";
        var $catofcontests_id = $("#contest_category").val();
        var $open_date = $("#contest_open_date").val();
        var $end_date = $("#contest_end_date").val();
        if($catofcontests_id > 0 && $open_date.length > 8 && $end_date.length > 8) {
          Swal.disableButtons();
          Swal.showLoading();
          var $vFD = new FormData();
          $vFD.append("catofcontests_id", $catofcontests_id);
          $vFD.append("open_date", $open_date);
          $vFD.append("end_date", $end_date);
          // Create Order to server
          this.RService.post(cMethod, $vFD).subscribe(
            (response: any) => {
              if(response.status == true) {
                Swal.hideLoading();
                Swal.fire({
                  title: 'ტრანზაქციის სტატუსი',
                  text: response.result.msg,
                  icon: 'success',
                  showConfirmButton: false,
                  position: 'top-end',
                  timer: 2500
                });
              }
            },
            (error) => {
              Swal.hideLoading();
            }
          );

        }else{
          //Swal.fire('','მიუთითეთ პარამეტრები სწორად', 'warning');
        }
      },
      (error) => {
        Swal.fire('შეცდომა',error, 'error');
      }
    );
  }

  public addNewContestCategory(){
    var vHTMLTemplate = `
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label for="contest_end_date">კონკურსის კატეგორია</label>
        <div class="position-relative has-icon-left">
          <input type="text" id="catofcontest_name" class="form-control" />
        </div>
      </div>
    </div>
  </div>
    `;
    Swal.fire({
      title: 'ახალი კონკურსის კატეგორიის დამატება',
      html: vHTMLTemplate,
      focusConfirm: false,
      confirmButtonText: "დამატება",
      showLoaderOnConfirm: true
    }).then(
      async (result) => {
        const cMethod = "contest/addcontestcategory";
        var $catofcontest_name = $("#catofcontest_name").val();
        if($catofcontest_name.length > 0) {
          Swal.disableButtons();
          Swal.showLoading();
          var $vFD = new FormData();
          $vFD.append("catofcontest_name", $catofcontest_name);
          // Create Order to server
          this.RService.post(cMethod, $vFD).subscribe(
            (response: any) => {
              if(response.status == true) {
                Swal.hideLoading();
                Swal.fire({
                  title: 'ტრანზაქციის სტატუსი',
                  text: response.result.msg,
                  icon: 'success',
                  showConfirmButton: false,
                  position: 'top-end',
                  timer: 2500
                });
              }
            },
            (error) => {
              Swal.hideLoading();
            }
          );

        }else{
          //Swal.fire('','მიუთითეთ პარამეტრები სწორად', 'warning');
        }
      },
      (error) => {
        Swal.fire('შეცდომა',error, 'error');
      }
    );
  }

}
