import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/datasource/rest.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less']
})
export class UserViewComponent implements OnInit {

  private method = "user/details/id/";
  private UserId: number = 0;
  public User: {};

  constructor(private RService: RestService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params =>{
      this.UserId = params.id;
      this.getUser(this.UserId);
    });
  }

  public getUser($pId): void {
    this.RService.get(this.method + $pId).subscribe((response: any) => {
      if (response.status === true) {
          this.User = response.result;
        }
    });
  }

}
