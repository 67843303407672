import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { UtilsService } from '../../datasource/services/utils/utils.service';

@Component({
  selector: 'app-mainmenu',
  templateUrl: './mainmenu.component.html',
  styleUrls: ['./mainmenu.component.less']
})
export class LayoutMainmenuComponent implements OnInit {

  public menuViewPermissions = {
    apps: false,
    devices: false,
    users: false,
    contests: false,
    programOptions: false
  };
  public appsCount = 0;

  constructor(private UService: UtilsService) { }

  ngOnInit(): void {
    $("#main-menu-navigation li a").click(function () {
      //$(this).parent("li").parent("ul").find("li").removeClass("active");
      $("#main-menu-navigation li").removeClass("active");
      $(this).parent("li").addClass("active");
    });

    this.UService.getUserPermission().subscribe((result) => {
        result.forEach(element => {
            element.permission.forEach(permission => {
                switch (permission.id) {
                  case "1":
                    this.menuViewPermissions.users = true;
                    break;
                  case "3":
                    this.menuViewPermissions.apps = true;
                    break;
                  case "6":
                    this.menuViewPermissions.devices = true;
                    break;
                  case "10":
                    this.menuViewPermissions.contests = true;
                    break;
                }
            });
        });
    });
  }
}
