import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestService } from 'src/app/datasource/rest.service';

@Component({
  selector: 'app-apps-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class AppsListComponent implements OnInit {

  public method = "apps/list";
  public list = [];
  public contestTitle = {
    visible: false,
    title: ""
  };
  public pagination = {
    currentPage: 1,
    itemsPerPage: 10,
    maxSizeOfPages: 25
  };
  public token: String;

  constructor(private RService: RestService, private route: ActivatedRoute) {
    this.token = this.RService.getToken();
  }

  ngOnInit(): void {
    this.loadAppsList();
  }

  private loadAppsList(): void {
    this.route.data.subscribe((data) => {
      if(data.filter == 'contest' && data.id > 0){
        this.method = this.method + '/' + data.filter + '/' + data.id;
        this.contestTitle.visible = true;
      }
      this.RService.get(this.method).subscribe((response: any) => {
        if (response.status === true) {
          this.list = response.result;
          if(this.list.length > 0){
            this.contestTitle.title = this.list[0].contest;
          }else{
            this.contestTitle.visible = false;
          }
        }else{
          alert(response.result);
        }
      });
    });
  }
}
