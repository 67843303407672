import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/datasource/config';
import { RestService } from 'src/app/datasource/rest.service';
import { FnParam } from '@angular/compiler/src/output/output_ast';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.less'],
  providers: [Config]
})
export class EmailTemplateComponent implements OnInit {

  public hrefPrefix;
  public method = "emails/templates/format/json/";
  public list = [];

  constructor(private config: Config, private RService: RestService, private route: ActivatedRoute) {
    this.hrefPrefix = config.get().hrefPrefix;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params =>{
      this.RService.get(this.method + 'id/' + params.id + '/').subscribe((response: any) => {
      if (response.status === true) {
          this.list = response.result;
        }
      })
    })
  }
}
