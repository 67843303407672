import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/datasource/rest.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.less']
})
export class UserLogoutComponent implements OnInit {

  constructor(private RService: RestService, private router: Router) { }

  ngOnInit() {
    let vToken = this.RService.getToken();
    this.RService.get('user/logout/token/' + vToken).subscribe((resp: any) => {
      if (resp.status === true) {
        this.RService.removeToken();
        $("#header_user_full_name").html("");
        Swal.fire({
          title: 'ავტორიზაციის სტატუსი',
          text: resp.result,
          icon: 'success',
          showConfirmButton: false,
          position: 'top-end',
          timer: 2500
        }).finally(() => {
           this.router.navigate(['/signin']);
        });
      }
    });
  }

}
