import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/datasource/config';
import { RestService } from 'src/app/datasource/rest.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-serverinfo',
  templateUrl: './serverinfo.component.html',
  styleUrls: ['./serverinfo.component.less'],
  providers: [Config]
})
export class SettingsServerinfoComponent implements OnInit {

  public hrefPrefix;
  public method = "settings/serverinfo/format/json/";
  public vServer: any = {
    application_server: {
        server: {},
        version: ""
    },
    database_server: ""
};
  constructor(private config: Config, private RService: RestService, private route: ActivatedRoute) {
    this.hrefPrefix = config.get().hrefPrefix;
  }

  ngOnInit(): void {
    // Server info request
    this.RService.get(this.method).subscribe(async (response: any) => {
      if (response.status === true) {
          this.vServer = await response.result;
        }
    });
  }

}
