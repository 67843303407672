import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AgGridModule } from 'ag-grid-angular';

import { Config } from './datasource/config';


import { APP_BASE_HREF } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutHeaderComponent } from './layout/header/header.component';
import { LayoutFooterComponent } from './layout/footer/footer.component';
import { AuthGuardService } from './datasource/auth/auth-guard.service';
import { UserLogoutComponent } from './modules/user/logout/logout.component';
import { IndexComponent } from './modules/index/index.component';
import { UserLoginComponent } from './modules/user/login/login.component';
import { InternalservererrorComponent } from './modules/httpstatuses/internalservererror/internalservererror.component';
import { PagenotfoundComponent } from './modules/httpstatuses/pagenotfound/pagenotfound.component';
import { UnautorizedComponent } from './modules/httpstatuses/unautorized/unautorized.component';
import { BadrequestComponent } from './modules/httpstatuses/badrequest/badrequest.component';
import { LoaderInterceptor } from './datasource/services/loader/loader.interceptors';
import { LoaderComponent } from './layout/loader/loader.component';
import { UserProfileComponent } from './modules/user/profile/profile.component';
import { BoolPipe } from './pipes/bool.pipe';
import { AppsListComponent } from './modules/apps/list/list.component';
import { AppEditComponent } from './modules/apps/edit/edit.component';
import { AppViewComponent } from './modules/apps/view/view.component';
import { UserListComponent } from './modules/user/list/list.component';
import { SettingsServerinfoComponent } from './modules/settings/serverinfo/serverinfo.component';
import { EmailTemplateComponent } from './modules/emails/template/template.component';
import { EmailsQueueComponent } from './modules/emails/queue/queue.component';
import { EmailConfigComponent } from './modules/emails/config/config.component';
import { Int2StrPipe } from './pipes/int2-str.pipe';
import { EmailTemplatesComponent } from './modules/emails/templates/templates.component';
import { AppsRaportsComponent } from './modules/apps/raports/raports.component';
import { BaseListComponent } from './base/component/list/list.component';
import { LayoutMainmenuComponent } from './layout/mainmenu/mainmenu.component';
import { LayoutCustomizerComponent } from './layout/customizer/customizer.component';
import { DevicesListComponent } from './modules/devices/list/list.component';
import { NotificationsListComponent } from './modules/notification/list/list.component';
import { ContestsListComponent } from './modules/contests/list/list.component';
import { DocPrintComponent } from './modules/docs/print/print.component';
import { AppsTransactionsComponent } from './modules/apps/transactions/transactions.component';
import { BaseUserComponent } from './base/component/user/user.component';
import { BaseEditComponent } from './base/component/edit/edit.component';
import { BaseAddComponent } from './base/component/add/add.component';
import { UtilsService } from './datasource/services/utils/utils.service';
import { UserViewComponent } from './modules/user/view/view.component';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    // Base
    BaseListComponent,
    BaseUserComponent,
    BaseEditComponent,
    BaseAddComponent,
    // Layout
    LayoutMainmenuComponent,
    LayoutCustomizerComponent,
    LayoutHeaderComponent,
    LayoutFooterComponent,
    // User
    UserListComponent,
    UserLoginComponent,
    UserProfileComponent,
    UserLogoutComponent,
    UserViewComponent,
    // Apps
    AppsListComponent,
    AppEditComponent,
    AppViewComponent,
    AppsTransactionsComponent,
    AppsRaportsComponent,
    // Emails
    EmailTemplateComponent,
    EmailsQueueComponent,
    EmailConfigComponent,
    EmailTemplatesComponent,
    // Contests
    ContestsListComponent,
    // HTTP statuses
    InternalservererrorComponent,
    PagenotfoundComponent,
    UnautorizedComponent,
    BadrequestComponent,

    LoaderComponent,
    BoolPipe,
    SettingsServerinfoComponent,
    Int2StrPipe,
    DevicesListComponent,
    NotificationsListComponent,
    DocPrintComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    NgxChartsModule,
    NgbModule,
    AgGridModule.withComponents([])
  ],
  exports: [
    LayoutHeaderComponent,
    LayoutFooterComponent,
    LayoutCustomizerComponent,
    LayoutMainmenuComponent
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    AuthGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    Config,
    UtilsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
