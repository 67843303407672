import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RestService } from 'src/app/datasource/rest.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { version } from '../../../../package.json';
import { UtilsService } from '../../datasource/services/utils/utils.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class LayoutHeaderComponent implements OnInit {
  public searchForm: FormGroup;
  public notifications: [] = [];
  public initInfo: any;
  private method = "notification/list";
  public version: string = version;
  public layout_header_count_of_notifications = 0;
  public fullname = "";
  public avatar = "../uploads/avatars/blank.jpg";

  constructor(private RService: RestService, private UService: UtilsService) { }

  ngOnInit() {
    this.userInitialize();
    this.loadNotifications();
    this.RService.initializeUserDetails();
  }

  private loadNotifications(){
    this.RService.get(this.method).subscribe((response) => {
        if(response.status == true){
          this.layout_header_count_of_notifications = response.result.length;
          this.notifications = response.result;
        }
      },
      (error) => {
        Swal.fire({
          title: 'ავტორიზაციის სტატუსი',
          text: error,
          icon: 'error',
          showConfirmButton: false,
          position: 'top-end',
          timer: 2500
        })
      }
    );
  }

  private userInitialize(){
    this.UService.getUserPermission().subscribe(
      (response) => {
        this.fullname = response.fullname;
        this.avatar = response.avatar;
      }
    );
  }

}
