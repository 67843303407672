import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bool2str'
})
export class BoolPipe implements PipeTransform {

  transform(value: any, ...args: any[]): String {
    return value ? "კი" : "არა";
  }

}
