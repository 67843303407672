import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'int2Str'
})
export class Int2StrPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if(value == 1){
      return "კი";
    }else if(value == 2){
      return "არა";
    }
  }

}
