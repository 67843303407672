import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Config } from 'src/app/datasource/config';
import { RestService } from 'src/app/datasource/rest.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less'],
  providers: [Config]
})
export class BaseListComponent implements OnInit {

  @Input() input;
  @Output() output: EventEmitter<any> = new EventEmitter();

  public hrefPrefix;
  public method: string;
  private _list: Array<any> = [];

  constructor(public RService: RestService) {
    //let lConfig = new Config();
    //this.hrefPrefix = lConfig.get().hrefPrefix;
  }

  ngOnInit() {

  }

  public run(pCallback: any) {
     this.RService.get(this.method).subscribe(pCallback);
  }
/*
  get method(): string {
    return this.method;
  }

  set method(pMethod: string) {
    this.method = pMethod;
  }
*/
  get list(): Array<any> {
    return this._list;
  }

  set list(pList: Array<any>) {
    this._list = pList;
  }

}
