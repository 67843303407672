import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RestService } from 'src/app/datasource/rest.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';

var multi = [
  {
    "name": "მიღებული",
    "series": [
      {
        "name": "2010",
        "value": 7870000
      },
      {
        "name": "2011",
        "value": 8270000
      }
    ]
  },
  {
    "name": "დახარვეზებული",
    "series": [
      {
        "name": "2010",
        "value": 5000002
      },
      {
        "name": "2011",
        "value": 5800000
      }
    ]
  },
  {
    "name": "უარყოფილი",
    "series": [
      {
        "name": "2010",
        "value": 7300000
      },
      {
        "name": "2011",
        "value": 8940000
      }
    ]
  },
  {
    "name": "დადასტურებული",
    "series": [
      {
        "name": "2010",
        "value": 7300000
      },
      {
        "name": "2011",
        "value": 8940000
      }
    ]
  },
  {
    "name": "გამარჯვებული",
    "series": [
      {
        "name": "2010",
        "value": 7300000
      },
      {
        "name": "2011",
        "value": 8940000
      }
    ]
  },
  {
    "name": "მომხმარებლის მიერ მოწერილი განმარტება",
    "series": [
      {
        "name": "2010",
        "value": 7300000
      },
      {
        "name": "2011",
        "value": 8940000
      }
    ]
  },
];


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.less']
})
export class IndexComponent implements OnInit {

  public data: any;
  multi: any[];
  view: any[] = [1000, 400];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'განცხადებები';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'რაოდენობა';
  animations: boolean = true;

  colorScheme = {
    domain: ['blue', 'red', 'green']
  };

  onSelect(event) {
    console.log(event);
  }

  constructor(private RService: RestService) {
    Object.assign(this, { multi });
    this.multi = multi;
  }

  ngOnInit() {
    //this.getStatisticData();
  }


  private getStatisticData(){
    let modelsMethod = "statistic/last/month";
    this.RService.get(modelsMethod).subscribe((resp) => {
      if (resp.status === true) {
        this.data = resp.result;
      }
    });
  }

}
