import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/datasource/rest.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { Config } from 'src/app/datasource/config';


@Component({
  selector: 'app-app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less']
})
export class AppViewComponent implements OnInit {

  public hrefPrefix;
  public serverAddress:string =  "";
  public method = "apps/list/app_id/";
  public app = {
        id: "",
        user_id: "",
        title: "",
        contest_id: "",
        film_name: "",
        film_name_geo: "",
        film_name_eng: "",
        status: "4",
        RequestedBudget: "",
        AltBudget: "",
        project_budget: "",
        film_language: "",
        lenght: "",
        video_url: "",
        video_format: "",
        poster: "",
        project_start_date: "",
        project_end_date: "",
        add_date: "",
        is_winner: "",
        ispreapp: "",
        is_show: "",
        user: "",
        contest: "",
        status_name: "",
        status_color: "",
        private: "",
        app_status_description: [
            {
                id: "",
                user_id: "",
                app_id: "",
                app_status_id: "",
                description: "",
                status_name: "",
                status_color: "",
                created_at: "",
                user_fullname: ""
            }
        ],
        contact_info: [
            {
                id: "",
                app_id: "",
                contact_info_type_id: "1",
                company_name: "",
                full_name: "",
                phone: "",
                email: "",
                amount: ""
            }
        ],
        files: [
            {
                id: "",
                user_id: "",
                app_id: "",
                file_type_id: "",
                mime_type: "application/pdf",
                mime_type_id: "0",
                ext: "",
                filename: "",
                file_desc: "",
                add_date: "",
                transfer_date: "",
                transfered_to_server: "",
                file_type_name: ""
            }
        ],
        transactions: [
          {
              id: "1",
              list_tr_status_id: "1",
              user_id: "58",
              app_id: "3",
              tr_type: "1",
              amount: "0",
              status: "1",
              add_date: "იტვირთება...",
              status_name: "იტვირთება...",
              user_full_name: "იტვირთება..."
          }
        ]
    };
  public app_statuses: any;
  public statuses: any;
  public app_offers: {};
  public appForm: FormGroup;
  public token: String;
  private appId;
  //<i class="fas fa-sync fa-spin"></i>

  constructor(private RService: RestService, private route: ActivatedRoute) {
    let conf = new Config();
    this.token = this.RService.getToken();
    this.serverAddress = conf.get().publicBaseURL;
    this.appForm = new FormGroup({
      app_status: new FormControl(),
      description: new FormControl()
   });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params =>{
      this.appId = params.id;
      this.getApp(this.appId);
    });
  }

  public getApp($pId): void {
    this.RService.get(this.method + $pId).subscribe((response: any) => {
      if (response.status === true) {
          this.app = response.result;
          this.getStatuses();
        }
    });
  }

  public getStatuses(): void {
    const cMethod = "lists/statuses";
    this.RService.get(cMethod).subscribe((response: any) => {
      if (response.status === true) {
          this.statuses = response.result;
        }
    });
  }

  public changeAppStatus(): void {
    let $vStatusId = this.appForm.controls.app_status.value;
    let $pDescription = this.appForm.controls.description.value;
    const vhttpParams = new FormData();
    var $status = true;
    if(!$vStatusId || $vStatusId == null){
      $status = false;
      Swal.fire({
        title: 'შენიშვნა',
        text: 'გთხოვთ აირჩიეთ სტატუსი',
        icon: 'warning',
        showConfirmButton: false,
        position: 'top-end',
        timer: 2500
      });
    }
    if(!$pDescription || $pDescription == null){
      $status = false;
      Swal.fire({
        title: 'შენიშვნა',
        text: 'გთხოვთ შეიყვანოთ სტატუსის აღწერა',
        icon: 'warning',
        showConfirmButton: false,
        position: 'top-end',
        timer: 2500
      });
    }
    if($status == true){
      vhttpParams.append('app_id', this.appId);
      vhttpParams.append('status_id', $vStatusId);
      vhttpParams.append('description', $pDescription);
      this.RService.post('apps/addstatus', vhttpParams).subscribe(
        (response) => {
          //alert(response.result);
          if(response.status == true){
            Swal.fire({
              title: 'ტრანზაქციის სტატუსი',
              text: response.result,
              icon: 'success',
              showConfirmButton: false,
              position: 'top-end',
              timer: 2500
            });
            this.getApp(this.appId);
          }else{
            Swal.fire({
              title: 'ტრანზაქციის სტატუსი',
              text: response.result,
              icon: 'error',
              showConfirmButton: false,
              position: 'top-end',
              timer: 2500
            })
          }

        },
        (error) => {
          Swal.fire({
            title: 'შეცდომა',
            text: error.message,
            icon: 'error',
            showConfirmButton: true
          })
        }
      );
    }
  }

  public doVisa(): void {
    var vHTMLTemplate = `
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>კომენტარი</label>
          <div class="position-relative has-icon-left">
            <textarea id="comment" class="form-control"></textarea>
          </div>
        </div>
      </div>
    </div>`;
      Swal.fire({
        title: 'განხადების ვიზირება',
        html: vHTMLTemplate,
        focusConfirm: false,
        confirmButtonText: "ვიზირება",
        showLoaderOnConfirm: true
      }).then(
        async (result) => {
          const cMethod = "apps/visa";
          var $amount = $("#amount").val();
          var $tr_src = $("#tr_src option:selected").val();
          if($amount.length > 0) {
            Swal.disableButtons();
            Swal.showLoading();
            var $vFD = new FormData();
            $vFD.append("list_tr_status_id", $tr_src);
            $vFD.append("app_id", this.app.id.toString());
            $vFD.append("amount", $amount);

            this.RService.post(cMethod, $vFD).subscribe(
              (response: any) => {
                if(response.status == true) {
                  this.getApp(this.appId);
                  Swal.hideLoading();
                  Swal.fire({
                    title: 'ტრანზაქციის სტატუსი',
                    text: response.result.msg,
                    icon: 'success',
                    showConfirmButton: false,
                    position: 'top-end',
                    timer: 2500
                  });
                }
              },
              (error) => {
                Swal.hideLoading();
              }
            );

          }else{
            //Swal.fire('','მიუთითეთ პარამეტრები სწორად', 'warning');
          }
        },
        (error) => {
          Swal.fire('შეცდომა',error, 'error');
        }
      );
  }

  public addTransaction(): void {
    var vHTMLTemplate = `
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label for="contest_end_date">ტრანზაქციის წყარო</label>
        <div class="position-relative has-icon-left">
          <select class="form-control" id="tr_src">
            <option value="1">კინემატოგრაფია</option>
            <option value="2">ალტერნატიული წყარო</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label for="contest_end_date">თანხის რაოდენობა</label>
        <div class="position-relative has-icon-left">
          <input type="number" id="amount" class="form-control" />
        </div>
      </div>
    </div>
  </div>
    `;
    Swal.fire({
      title: 'ახალი კონკურსის კატეგორიის დამატება',
      html: vHTMLTemplate,
      focusConfirm: false,
      confirmButtonText: "დამატება",
      showLoaderOnConfirm: true
    }).then(
      async (result) => {
        const cMethod = "transaction/add";
        var $amount = $("#amount").val();
        var $tr_src = $("#tr_src option:selected").val();
        if($amount.length > 0) {
          Swal.disableButtons();
          Swal.showLoading();
          var $vFD = new FormData();
          $vFD.append("list_tr_status_id", $tr_src);
          $vFD.append("app_id", this.app.id.toString());
          $vFD.append("amount", $amount);

          this.RService.post(cMethod, $vFD).subscribe(
            (response: any) => {
              if(response.status == true) {
                this.getApp(this.appId);
                Swal.hideLoading();
                Swal.fire({
                  title: 'ტრანზაქციის სტატუსი',
                  text: response.result.msg,
                  icon: 'success',
                  showConfirmButton: false,
                  position: 'top-end',
                  timer: 2500
                });
              }
            },
            (error) => {
              Swal.hideLoading();
            }
          );

        }else{
          //Swal.fire('','მიუთითეთ პარამეტრები სწორად', 'warning');
        }
      },
      (error) => {
        Swal.fire('შეცდომა',error, 'error');
      }
    );
  }
}
