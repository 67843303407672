import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/datasource/rest.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class UserLoginComponent implements OnInit {

  public AuthForm: FormGroup;
  returnUrl: any;

  constructor(private RService: RestService, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router) {
    this.AuthForm = this.formBuilder.group({
      phone: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get fields() { return this.AuthForm.controls; }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  public signin() {
    const vhttpParams = new FormData();
    vhttpParams.append('username', this.fields.phone.value);
    vhttpParams.append('password', this.fields.password.value);
    vhttpParams.append('op', 'login');

    this.RService.post('user/signin', vhttpParams).subscribe((resp) => {
      if (resp.status === true) {
        if(RestService.setUserToken(resp.result.token)){
          $("#header_user_full_name").html(resp.result.fullname);
          this.RService.initializeUserDetails();
          Swal.fire({
            title: 'ავტორიზაციის სტატუსი',
            text: 'ავტორიზაციამ ჩაიარა წარმატებით',
            icon: 'success',
            showConfirmButton: false,
            position: 'top-end',
            timer: 2500
          }).finally(() => {
              if(this.returnUrl){
                if(!this.returnUrl){
                  this.router.navigate(['#/apps/null/0']);
                }else{
                  this.router.navigate([this.returnUrl]);
                }
              }else{
                this.router.navigate(['/signin']);
              }
          });
        }else{
          alert('Something went wrong');
        }
      } else if (resp.status === false) {
        Swal.fire({
          title: 'ავტორიზაციის სტატუსი',
          text: resp.result.msg,
          icon: 'error',
          showConfirmButton: false,
          position: 'top-end',
          timer: 2500
        })
      }
    });
  }

}
