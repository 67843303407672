import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './datasource/auth/auth-guard.service';
import { IndexComponent } from './modules/index/index.component';
import { UserListComponent } from './modules/user/list/list.component';
import { SettingsServerinfoComponent } from './modules/settings/serverinfo/serverinfo.component';
import { EmailsQueueComponent } from './modules/emails/queue/queue.component';
import { EmailConfigComponent } from './modules/emails/config/config.component';
import { EmailTemplateComponent } from './modules/emails/template/template.component';
import { EmailTemplatesComponent } from './modules/emails/templates/templates.component';;
import { ProductEditComponent } from './modules/product/edit/edit.component';
import { PagenotfoundComponent } from './modules/httpstatuses/pagenotfound/pagenotfound.component';
import { AppsListComponent } from './modules/apps/list/list.component';
import { AppViewComponent } from './modules/apps/view/view.component';
import { DevicesListComponent } from './modules/devices/list/list.component';
import { NotificationsListComponent } from './modules/notification/list/list.component';
import { ContestsListComponent } from './modules/contests/list/list.component';
import { UserProfileComponent } from './modules/user/profile/profile.component';
import { UserLoginComponent } from './modules/user/login/login.component';
import { UserLogoutComponent } from './modules/user/logout/logout.component';
import { UserViewComponent } from './modules/user/view/view.component';

const routes: Routes = [
  {
    path: '', component: IndexComponent,
    canActivate: [
      AuthGuardService
    ]
  },
  // Apps
  {
    path: 'apps/:filter/:id',
    component: AppsListComponent,
    canActivate: [
      AuthGuardService
    ],
    data: {
      filter: '', //contest
      id: 0
    }
  },
  {
    path: 'app/:id',
    component: AppViewComponent,
    canActivate: [
      AuthGuardService
    ]
  },
  {
    path: 'app/edit/:id',
    component: ProductEditComponent,
    canActivate: [
      AuthGuardService
    ]
  },
  // Contests
  {
    path: 'contests',
    component: ContestsListComponent,
    canActivate: [
      AuthGuardService
    ]
  },
  //Devices
  {
    path: 'devices',
    component: DevicesListComponent,
    canActivate: [
      AuthGuardService
    ]
  },
  // Settings
  {
    path: 'settings/serverinfo',
    component: SettingsServerinfoComponent,
    canActivate: [
      AuthGuardService
    ]
  },
  //Emails
  {
    path: 'email/queue/list',
    component: EmailsQueueComponent,
    canActivate: [
      AuthGuardService
    ]
  },
  {
    path: 'email/templates/list',
    component: EmailTemplatesComponent,
    canActivate: [
      AuthGuardService
    ]
  },
  {
    path: 'email/template/view/:id',
    component: EmailTemplateComponent,
    canActivate: [
      AuthGuardService
    ]
  },
  {
    path: 'email/config',
    component: EmailConfigComponent,
    canActivate: [
      AuthGuardService
    ]
  },
  // User routers bundle
  {
    path: 'signin',
    component: UserLoginComponent
  },
  {
    path: 'users',
    component: UserListComponent,
    canActivate: [
      AuthGuardService
    ]
  },
  {
    path: 'user/profile',
    component: UserProfileComponent,
    canActivate: [
      AuthGuardService
    ]
  },
  {
    path: 'user/:id',
    component: UserViewComponent,
    canActivate: [
      AuthGuardService
    ]
  },
  {
    path: 'logout',
    component: UserLogoutComponent,
    canActivate: [
      AuthGuardService
    ]
  },
  // Notifications
  {
    path: 'notifications',
    component: NotificationsListComponent,
    canActivate: [
      AuthGuardService
    ]
  },

  {
    path: '**',
    component: PagenotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
