import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/datasource/rest.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less']
})
export class UserProfileComponent implements OnInit {

  public account = {
      id: "",
      username: "",
      fullname: "",
      phone: "",
      email: "",
      pnumber: "",
      ser_role_id: "",
      activities: "",
      birthday: "",
      created_at: "",
      avatar: "",
      permissions: [
          {
              list_user_role_id: "",
              list_user_role_name: "",
              permission: [
                  {
                      list_user_role_id: "",
                      id: "",
                      name: "",
                      controller: "",
                      action: "",
                      http_method: "",
                      http_method_comment: "",
                      created_at: "",
                      creator_id: ""
                  }
              ]
          }
      ]
  };
  private method = "user/profile";
  public avatar = "app-assets/images/portrait/small/avatar-s-26.png";

  constructor(private RService: RestService) { }

  ngOnInit() {
    this.initRequest();
  }

  private initRequest(){
    this.RService.get(this.method).subscribe((resp) => {
      if(resp.status == true){
        this.account = resp.result;
        this.avatar = this.account.avatar;
      }
    });
  }

  public onFileChange(event){
    const cMethod = "user/setavatar";
    var $files = event.target.files;
    var vFD = new FormData();
    vFD.append("avatar", $files[0], $files[0].name);
    this.RService.post(cMethod, vFD).subscribe(
      (response) => {
        if(response.status == true){
          $("#avatar_img_el").attr("src", response.result.filename);
          $("#user_profile_avatar_el").attr("src", response.result.filename);
          Swal.fire({
            title: 'Status',
            text: response.result.msg,
            icon: 'success',
            showConfirmButton: false,
            position: 'top-end',
            timer: 1500
          });
        }
      }
    )
  }

  public saveChanges(){
    const cMethod = "user/profile";
    let vFD = new FormData();
    let fullname = $("#fullname").val();
    let phone = $("#phone").val();
    let email = $("#email").val();
    let pnumber = $("#pnumber").val();
    vFD.append('fullname', fullname);
    vFD.append('phone', phone);
    vFD.append('email', email);
    vFD.append('pnumber', pnumber);
    this.RService.post(cMethod, vFD).subscribe(
      (response) => {
        if(response.status == true) {
          Swal.fire({
            title: 'Status',
            text: response.result,
            icon: 'success',
            showConfirmButton: false,
            position: 'top-end',
            timer: 1500
          });
        }else{
          Swal.fire({
            title: 'Status',
            text: response.result,
            icon: 'warning',
            showConfirmButton: false,
            position: 'top-end',
            timer: 3000
          });
        }
      }
    )
  }


}
