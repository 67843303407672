import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/datasource/rest.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class AppEditComponent implements OnInit {

  public method = "apps/details/format/json/id/";
  public list = [];

  constructor(private RService: RestService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params =>{
      this.RService.get(this.method + params.id + '/').subscribe((response: any) => {
      if (response.status === true) {
          this.list = response.result;
        }
      });
    });
  }

  pageSave(pButton: HTMLButtonElement) {

    let vTitle: string;
    pButton.innerHTML = '<i class="fa fa-cog fa-spin"></i> მუშავდება...';
    setTimeout(() => {
      pButton.innerHTML = ' <i class="fa fa-floppy-o" aria-hidden="true"></i> მონაცემების განახლება';
    }, 1500);
    const promise = new Promise((resolve, reject) => {
     /* this.textEditors.forEach(Instance => {
        vTitle = this.getTitle(Instance.config.seq_id, Instance.config.lang_id);
        this._pageContentSave(Instance.config.seq_id, Instance.config.lang_id, Instance.data, vTitle);
      });*/
      resolve(true);
    });
    promise.catch(() => {
      /*pButton.innerHTML = '<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> დაფიქსირდა შეცდომა';
      setTimeout(() => {
        pButton.innerHTML = ' <i class="fa fa-floppy-o" aria-hidden="true"></i> მონაცემების განახლება';
      }, 3000);*/
    }).finally(() => {
      // pButton.innerHTML = ' <i class="fa fa-floppy-o" aria-hidden="true"></i> მონაცემების განახლება';
    });
  }

}
