import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/datasource/services/loader/loader.service';


@Component({
  selector: 'app-loading',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less']
})
export class LoaderComponent implements OnInit {
  loading: boolean;
  filename: String;
  min = 1;
  max = 12;
  constructor(private loaderService: LoaderService) {
    this.changePreloaderFileName();
    setInterval(() => {
      this.changePreloaderFileName();
    }, 10000);
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }
  ngOnInit() {
  }

  changePreloaderFileName() {
    let rand = Math.floor(Math.random() * (this.max - this.min + 1)) + this.min;
    this.filename = 'assets/imgs/preloader/' + rand + '.gif';
    return true;
  }

}
