import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/datasource/rest.service';
import * as $ from 'jquery';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class DevicesListComponent implements OnInit {
  private method = "device/list";
  public list = [
    {
      id: "0",
      device_name: "",
      user_name: "",
      device_id: "",
      created_at: "",
      creator_id: 0,
      user_fullname: ""
    }
  ];
  constructor(private RService: RestService) { }

  ngOnInit(): void {
    this.getList();
  }

  private getList() {
    this.RService.get(this.method).subscribe((response: any) => {
      if (response.status === true) {
        this.list = response.result;
      }else{
        alert(response.result);
      }
    });
  }

  public addDevice(){
    const cMethod = "device/add";
    const vhttpParams = new FormData();
    //Base info
    let $device_id = $("#device_id").val();
    let $device_name = $("#device_name").val();
    let $user_name = $("#user_name").val();

    vhttpParams.append('device_id', $device_id);
    vhttpParams.append('device_name', $device_name);
    vhttpParams.append('user_name', $user_name);

    this.RService.post(cMethod, vhttpParams).subscribe(
      (response) => {
        if (response.status === true) {
          Swal.fire({
            title: 'ტრანზაქციის სტატუსი',
            text: response.result,
            icon: 'success',
            showConfirmButton: false,
            position: 'top-end',
            timer: 2500
          });
        }else{
          Swal.fire({
            title: 'ტრანზაქციის სტატუსი',
            text: response.result,
            icon: 'error',
            showConfirmButton: false,
            position: 'top-end',
            timer: 2500
          })
        }
      },
      (error) => {
        Swal.fire({
          title: 'ტრანზაქციის სტატუსი',
          text: error.message,
          icon: 'error',
          showConfirmButton: false,
          position: 'top-end',
          timer: 2500
        })
      }
    );
  }

}
