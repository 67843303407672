import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpEvent, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Config } from './config';
import { Observable, throwError, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UtilsService } from './services/utils/utils.service';


@Injectable({
  providedIn: 'root'
})
export class RestService {
  private lconfig;
  public userDetails: Subject<any>;
  public baseURL: string;

  constructor(private http: HttpClient, private UService: UtilsService) {
    const llconfig = new Config();
    this.lconfig = llconfig.get();
  }

  public initializeUserDetails(){
    let initMethod = "index/initialize";
    this.get(initMethod).subscribe(
      (response) => {
        if(response.status == true){
          let initInfo = response.result;
          this.UService.setUser(initInfo);
        }
      }
    );
  }

  private getHeaders(): HttpHeaders {
    const vHttpHeaders = new HttpHeaders();
    vHttpHeaders.set('token', this.getToken());
    return vHttpHeaders;
  }

  public getToken() {
    const vToken = localStorage.getItem('token');
    return vToken;
  }

  public removeToken() {
    localStorage.removeItem('token');
  }

  /**
   * uploadPhoto
   */
  public uploadPhoto(pSegment: string, pFormData: any, pOptions: any) {
    pFormData.append('token', this.getToken());
    return this.http.post<any>(this.lconfig.baseURL + pSegment, pFormData, pOptions).pipe(map((event: any) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  public get(pSegment: string, pIsSilent = "false"): Observable<any> {
    var vHttpHeaders  = new HttpHeaders();
    vHttpHeaders.append("silent", pIsSilent);
    const vGetResult = this.http.get(this.lconfig.baseURL + pSegment, {
      headers: vHttpHeaders
    }).pipe(
      catchError(this.handleError)
    );
    return vGetResult;
  }

  public post(pSegment: string, pParams: FormData = new FormData(), pDeprecatedOption: any = {}): Observable<any> {
    const vPost = this.http.post(this.lconfig.baseURL + pSegment, pParams).pipe(
      catchError(this.handleError)
    );
    return vPost;
  }

  public put(pSegment: string, pParams: FormData = new FormData()): Observable<any> {
    const vPost = this.http.put(this.lconfig.baseURL + pSegment, pParams).pipe(
      catchError(this.handleError)
    );
    return vPost;
  }

  public delete(pSegment: string): Observable<any> {
    const vGetResult = this.http.delete(this.lconfig.baseURL + pSegment).pipe(
      catchError(this.handleError)
    );
    return vGetResult;
  }

  public setUserActivate(pUser: any): boolean {
    console.log(pUser);
    return true;
  }

  public static setUserToken(pToken: any) {
    localStorage.setItem('token', pToken);
    return true;
  }

  private getEventMessage(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        return `Uploading file "${file.name}" of size ${file.size}.`;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round(100 * event.loaded / event.total);
        return `File "${file.name}" is ${percentDone}% uploaded.`;

      case HttpEventType.Response:
        return `File "${file.name}" was completely uploaded!`;

      default:
        return `File "${file.name}" surprising upload event: ${event.type}.`;
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
