import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-badrequest',
  templateUrl: './badrequest.component.html',
  styleUrls: ['./badrequest.component.less']
})
export class BadrequestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
