import { DecimalPipe } from '@angular/common';
import { Component, OnInit, PipeTransform, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RestService } from 'src/app/datasource/rest.service';
import UserInterface from '../interface/user.interface';

@Component({
  selector: 'app-user-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less'],
  providers: [DecimalPipe]
})
export class UserListComponent implements OnInit {

  private method = "user/list";
  private statusListMethod = "user/statuslist";
  public statuslist: any[] = [];
  public list: any[] = [];
  public list$: Observable<any[]>;
  public pagination = {
    currentPage: 1,
    itemsPerPage: 10,
    maxSizeOfPages: 25
  };
  public filter = new FormControl('');

  //@ViewChild('agGrid') public agGrid: AgGridAngular;

  public fields = [
    {field: 'id', sortable: true, filter: true},
    {field: 'username', sortable: true, filter: true, rowGroup: true},
    {field: 'fullname', sortable: true, filter: true},
    {field: 'email', sortable: true, filter: true},
    {field: 'phone', sortable: true, filter: true},
    {field: 'pnumber', sortable: true, filter: true},
    {field: 'permissions', sortable: true, filter: true},
    {field: 'created_at', sortable: true, filter: true}
  ];

  public data = [
    {id: 0, username: 'იტვირთება...', fullname: 'იტვირთება...', email: 'იტვირთება...', phone: '', pnumber: '', permissions:'', created_at: ''}
  ];


  constructor(private RService: RestService, private pipe: DecimalPipe) { }

  ngOnInit(): void {
    this.loadUserList();
    this.loadUserStatusList();
  }
  
  // public getSelectedRows(): void {
  //   const selectedNodes = this.agGrid.api.getSelectedNodes();
  //   const selectedData = selectedNodes.map(node => node.data);
  //   const selectedDataStringPresentation = selectedData.map(node => `${node.make} ${node.model}`).join(', ');
  //   alert(`Selected nodes: ${selectedDataStringPresentation}`);
  // }


  private loadUserStatusList(): void {
    this.RService.get(this.statusListMethod).subscribe((response: any) => {
      if (response.status === true) {
          this.statuslist = response.result;
        }
    });
  }

  private loadUserList(): void {
    this.RService.get(this.method).subscribe((response: any) => {
      if (response.status === true) {
          this.list = response.result;
          this.data = this.list;
        }
    });
  }

  public search(): void {
    this.list = this.list.filter((value: any, index: number, array: any[]) => {
      let text = this.filter.value;
      return value.fullname.includes(text)
          //|| this.pipe.transform(value.email).includes(text)
          || this.pipe.transform(value.pnumber).includes(text)
          || this.pipe.transform(value.email).includes(text)
          || this.pipe.transform(value.created_at).includes(text);
    });
  }
  
}
