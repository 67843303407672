import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestService } from 'src/app/datasource/rest.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class ProductEditComponent implements OnInit {

  public method = "product";
  public list = [];
  constructor(private RService: RestService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.RService.get(this.method + '/' + params.id).subscribe((response: any) => {
        if (response.status === true) {
          this.list = response.result;
        }
      });
    })
  }
}
