import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private User = {
    id: "",
    username: "",
    fullname: "იტვირთება...",
    hone: "იტვირთება...",
    email: "იტვირთება...",
    pnumber: "იტვირთება...",
    user_role_id: "",
    ctivities: "იტვირთება...",
    birthday: "იტვირთება...",
    created_at: null,
    permissions: [
      {
        list_user_role_id: "",
        list_user_role_name: "იტვირთება...",
        permission: [
          {
            list_user_role_id: "",
            id: "",
            name: "იტვირთება...",
            controller: "იტვირთება...",
            action: "იტვირთება...",
            http_method: "იტვირთება...",
            http_method_comment: "წაკითხვა",
            created_at: "იტვირთება...",
            creator_id: ""
          }
        ]
      }
    ],
    avatar: "/uploads/avatars/58/73fa0dd44208427f63ab9da2e700e23b.jpg"
  };
  private UserPermissions: Subject<any[]>;

  constructor() {
    this.UserPermissions = new Subject<any>();
  }

  public getUser(): any {
    return this.User;
  }

  public setUser(pUser: any): void {
    this.User = pUser;
    this.UserPermissions.next(this.User.permissions);
  }

  public getUserPermission(): Observable<any> {
    return this.UserPermissions;
  }

}
