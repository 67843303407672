import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/datasource/rest.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class NotificationsListComponent implements OnInit {

  public list: [];
  private method = "notifications/list";

  constructor(private RService: RestService) { }

  ngOnInit(): void {
    this.loadNotifications();
  }

  private loadNotifications(){
    this.RService.get(this.method).subscribe((response: []) => {
        let $layout_header_count_of_notifications = response.length;
        $("#layout_header_count_of_notifications").html($layout_header_count_of_notifications);
        $("#layout_header_count_of_notifications1").html($layout_header_count_of_notifications);
        this.list = response;
    },
    (error) => {
      Swal.fire({
        title: 'ავტორიზაციის სტატუსი',
        text: error,
        icon: 'error',
        showConfirmButton: false,
        position: 'top-end',
        timer: 2500
      })
    }
    );
  }

}
